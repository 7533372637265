import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Layout from '../layout';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Footer = makeShortcode("Footer");
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "about"
    }}>{`About`}</h1>
    <p>{`Design Systems Coalition NYC is a niche meetup for people enthusiastic about design systems.
This includes professionals, freelancers, designers and developers, brand designers, managers and directors, product managers, and anyone else interested in learning more or sharing their own experiences with building design systems.`}</p>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://design.systems/coalition/"
      }}>{`Design Systems Coalition`}</a>{`
first formed in San Francisco and has chapters popping up in cities around the globe.
It was born out of the Design Systems slack community started by
`}<a parentName="p" {...{
        "href": "https://twitter.com/jina"
      }}>{`Jina Anne`}</a>{`.`}</p>
    <p>{`The NYC chapter is organized by
`}<a parentName="p" {...{
        "href": "https://twitter.com/broccolini"
      }}>{`Diana Mounter`}</a>{`,
Design Operations Manager at GitHub;
`}<a parentName="p" {...{
        "href": "https://twitter.com/TomTaki"
      }}>{`Tom Takigayama`}</a>{`,
Product Design Manager at Justworks;
`}<a parentName="p" {...{
        "href": "https://twitter.com/itschesley"
      }}>{`Chesley Andrews`}</a>{`,
Product Design Manager at Betterment; and
`}<a parentName="p" {...{
        "href": "https://twitter.com/jxnblk"
      }}>{`Brent Jackson`}</a>{`,
Software Developer at Gatsby.`}</p>
    <pre><code parentName="pre" {...{}}>{`TK CFP Component
https://t.co/nWT3FMTXCy
`}</code></pre>
    <Footer mdxType="Footer" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      